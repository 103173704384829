import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button'
import styles from '../index.module.scss'
import classNames from 'classnames'
import { fetchHelicopter, fetchRealEstate, fetchYachts, ObjectProps } from '../../../api'
import { Link, useNavigate } from 'react-router-dom'

const cn = classNames.bind(styles)

type PopularObjectsType = 'yachts' | 'helicopters' | 'real-estate'
const PopularObjects = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [type, setType] = useState<PopularObjectsType>('real-estate')
  const [objectList, setObjectList] = useState<ObjectProps[]>([])

  const getInfo = async (type: PopularObjectsType) => {
    switch (type) {
      case 'real-estate':
        return await fetchRealEstate(setObjectList)
      case 'yachts':
        return await fetchYachts(setObjectList)
      case 'helicopters':
        return await fetchHelicopter(setObjectList)
      default:
        return await fetchRealEstate(setObjectList)
    }
  }

  useEffect(() => {
    getInfo(type)
  }, [])

  return (
    <section className={cn('container', styles.PopularObjectsSection)}>
      <div className={styles.PopularObjectsHeader}>
        <h3>
          {t('popularObjects')}
        </h3>

        <div className={styles.PopularObjectsTypes}>
          <Button
            tag='outlined'
            className={type === 'real-estate' ? styles.PopularObjectsTypesActive : ''}
            onClick={async () => {
              setType('real-estate')
              await getInfo('real-estate')
            }}
          >
            {t('realEstate')}
          </Button>
          <Button
            tag='outlined'
            className={type === 'helicopters' ? styles.PopularObjectsTypesActive : ''}
            onClick={async () => {
              setType('helicopters')
              await getInfo('helicopters')
            }}
          >
            {t('helicopters')}
          </Button>
          <Button
            tag='outlined'
            className={type === 'yachts' ? styles.PopularObjectsTypesActive : ''}
            onClick={async () => {
              setType('yachts')
              await getInfo('yachts')
            }}
          >
            {t('yachts')}
          </Button>
        </div>
      </div>

      <div className={styles.PopularObjectsImages}>
        <div>
          {
            objectList?.[0]?.images
            && (
              <Link key={objectList?.[0]?.id} to={`/offers/${objectList?.[0]?.id}`}>
                <img
                  src={objectList?.[0]?.images?.[0].image}
                  alt={objectList?.[0]?.name}
                  className={styles.PopularImgSmall}
                />
              </Link>
            )
          }
          {
            objectList?.[1]?.images
            && (
              <Link key={objectList?.[1]?.id} to={`/offers/${objectList?.[1]?.id}`}>
                <img
                  src={objectList?.[1]?.image || objectList?.[1]?.images?.[0]?.image}
                  alt={objectList?.[1]?.name}
                  key={objectList?.[1]?.id}
                  className={styles.PopularImgBig}
                />
              </Link>
            )
          }
        </div>
        <div>
          {
            objectList?.[2]?.images
            && (
              <Link key={objectList?.[2]?.id} to={`/offers/${objectList?.[2]?.id}`}>
                <img
                  src={objectList?.[2]?.image || objectList?.[2]?.images?.[0]?.image}
                  alt={objectList?.[2]?.name}
                  key={objectList?.[2]?.id}
                  className={styles.PopularImgBig}
                />
              </Link>
            )
          }
          {
            objectList?.[3]?.images
            && (
              <Link key={objectList?.[3]?.id} to={`/offers/${objectList?.[3]?.id}`}>
                <img
                  src={objectList?.[3]?.image || objectList?.[3]?.images?.[0]?.image}
                  alt={objectList?.[3]?.name}
                  key={objectList?.[3]?.id}
                  className={styles.PopularImgSmall}
                />
              </Link>
            )
          }
        </div>
        <div>
          {
            objectList?.[4]?.images
            && (
              <Link key={objectList?.[4]?.id} to={`/offers/${objectList?.[4]?.id}`}>
                <img
                  src={objectList?.[4]?.image || objectList?.[4]?.images?.[0]?.image}
                  alt={objectList?.[4]?.name}
                  key={objectList?.[4]?.id}
                  className={styles.PopularImgSmall}
                />
              </Link>
            )
          }
          {
            objectList?.[5]?.images
            && (
              <Link key={objectList?.[5]?.id} to={`/offers/${objectList?.[5]?.id}`}>
                <img
                  src={objectList?.[5]?.image || objectList?.[5]?.images?.[0]?.image}
                  alt={objectList?.[5]?.name}
                  key={objectList?.[5]?.id}
                  className={styles.PopularImgBig}
                />
              </Link>
            )
          }
        </div>
      </div>

      <Button
        onClick={() => navigate(type)}
      >
        {t('all')}
      </Button>
    </section>
  )
}

export default PopularObjects