
export default function typedKeys<T>(v: T) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.keys(v) as Array<keyof typeof v>
}

export const validateRussianPhoneNumber = (phoneNumber: string) => {
  const regex = /^(\+7)?[\s-]?\(?(9\d{2})\)?[\s-]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})$/
  return regex.test(phoneNumber)
}
