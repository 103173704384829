import React, {useEffect} from 'react'
import Button from '../../components/Button'
import Image404 from '../../assets/images/404.png'
import styles from './index.module.scss'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const cn = classNames.bind(styles)
const Page404 = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    const elements = document.querySelectorAll('footer')

    elements.forEach(element => {
      element.style.display = 'none'
    })

    return () => {
      elements.forEach(element => {
        element.style.display = ''
      })
    }
  }, [])

  return (
    <main className={cn('container', styles.Main)}>
      <section className={styles.Container}>
        <div className={styles.TextContainer}>
          <span>{t('404.error')}</span>
          <h1>
            {t('404.title')}
          </h1>

          <p>
            {t('404.description')}
          </p>

          <Button onClick={() => navigate('/')}>
            {t('backToHome')}
          </Button>
        </div>

        <div className={styles.ImageContainer}>
          <img src={Image404} alt="Page not found"/>
        </div>
      </section>
    </main>
  )
}

export default Page404