import { URL } from './constants'
import { QuestionItemProps } from '../pages/FAQ/components/QuestionItem.d'
import { Dispatch, SetStateAction } from 'react'
import axios from 'axios'
interface ImageProps {
  id: string
  name: string
  image: string
}

export interface FacilityProps {
  id: string
  name: string
  category: string
}
export interface ObjectProps {
  id: string
  name: string
  name_en: string,
  name_ru: string,
  image: string
  images: ImageProps[]
  facilities?: FacilityProps[]
  type?: 'real_estate' | 'yacht' | 'helicopter'
  description?: string
  country: ObjectProps
  lat: number
  lon: number
}

interface PostOfferProps {
  name?: string
  phone: string
  message: string
}

interface BecameAgentProps {
  name: string
  email: string
  phone: string
  message: string
  attachment?: Blob
  fileName?: string
}
export const fetchQuestions = async (setQuestions: Dispatch<SetStateAction<QuestionItemProps[]>>) => {
  try {
    const response = await fetch(URL('faq'))
    const jsonData = await response.json()
    setQuestions(jsonData as QuestionItemProps[] || [])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchCountries = async (setCountryList: Dispatch<SetStateAction<ObjectProps[]>>) => {
  try {
    const response = await fetch(URL('countries'))
    const jsonData = await response.json()
    setCountryList(jsonData as ObjectProps[] || [])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchYachts = async (setYachtList: Dispatch<SetStateAction<ObjectProps[]>>) => {
  try {
    const response = await fetch(URL('objects/?type=yacht'))
    const jsonData = await response.json()
    setYachtList(jsonData as ObjectProps[] || [])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchHelicopter = async (setHelicopterList: Dispatch<SetStateAction<ObjectProps[]>>) => {
  try {
    const response = await fetch(URL('objects/?type=helicopter'))
    const jsonData = await response.json()
    setHelicopterList(jsonData as ObjectProps[] || [])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchRealEstate = async (setRealEstateList: Dispatch<SetStateAction<ObjectProps[]>>) => {
  try {
    const response = await fetch(URL('objects/?type=real_estate'))
    const jsonData = await response.json()
    setRealEstateList(jsonData as ObjectProps[] || [])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchObjectById = async (id: string, setObject: Dispatch<SetStateAction<ObjectProps | undefined>>) => {
  try {
    const response = await fetch(URL(`objects/${id}`))
    const jsonData = await response.json()
    setObject(jsonData as ObjectProps)
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchObjectsByCountryId = async (id: string, setObject: Dispatch<SetStateAction<ObjectProps[]>>) => {
  try {
    const response = await fetch(URL(`objects/?country_id=${id}`))
    const jsonData = await response.json()
    setObject(jsonData as ObjectProps[])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const postReqGetOffer = async (data: PostOfferProps) => {
  try {
    const formData = new FormData()
    data.name && formData.append('name', data.name)
    formData.append('phone', data.phone)
    formData.append('message', data.message)
    formData.append('type', 'client')

    await axios.post(
      URL('callback-request/'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return true
  } catch (e) {
    console.log(e)
  }
}

export const postBecameAnAgent = async (data: BecameAgentProps) => {
  try {
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('email', data.email)
    formData.append('phone', data.phone)
    formData.append('message', data.message)
    formData.append('type', 'client')
    data.attachment && formData.append('attachment', data.attachment, data.fileName)

    await axios.post(
      URL('callback-request/'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return true
  } catch (e) {
    console.log(e)
  }
}


