export default {
  all: 'Смотреть все',
  home: 'Главная',
  countries: 'Страны',
  yachts: 'Яхты',
  helicopters: 'Вертолеты',
  realEstate: 'Недвижимость',

  popularObjects: 'Популярные объекты',
  bestCountriesForVacation: 'Лучшие страны для отдыха',

  policy: 'Политика конфиденциальности',

  404: {
    title: 'Страница\nне найдена',
    error: 'Ошибка 404',
    description: 'Вероятно, страница не существует или вы \n' +
      'ошиблись, когда вводили адрес в строке браузера.',
  },

  backToHome: 'Вернуться на главную',

  modal: {
    title: 'Укажите\nконтактные\nданные',
    description: 'Наш менеджер свяжется с вами \n' +
      'в течении 10 минут и расскажет \nподробнее',
  },

  allCountries: 'Все страны',

  Indonesia: 'Индонезия',
  FrenchPolynesia: 'Французская Полинезия',
  city: 'Город',

  facilities: 'Удобства',
  location: 'Местоположение',
  equipment: 'Оборудование',
  entertainment: 'Развлечения',
  salonAndCabins: 'Салон и Каюты',
  submitYourApplication: 'Оставить заявку',
  wrongFormat: 'Неверный формат',
  phoneNumber: 'Номер телефона',
  comment: 'Комментарий',
  getAnOffer: 'Получить предложение',
  ready: 'Готово',
  willContact: `Наш менеджер свяжется с вами \nв течении 10 минут и расскажет\nподробнее`,
  faq: 'Часто задаваемые вопросы',
  rent: "Арендуйте<br/> <strong>лучшие</strong> объекты<br/>по всему миру",
  villa: 'Вилла',
  yacht: 'Яхта',
  helicopter: 'Вертолет',
  dates: 'Даты',
  selectDates: 'Выберите даты',

  find: 'Найти',

  whatIsYourName: 'Как вас зовут?',
  requiredField: 'Обязательное поле',
  policyPage: {
    title: 'Политика конфиденциальности',
    date: 'Данная политика действительна с 01 Июня 2023 года',
    description: 'Настоящая политика созадана для того, чтобы проинформировать посетителей нашей страницы о том, как мы собираем, используем и раскрываем Персональные Данные \n' +
      'в случае если посетители принимают решение воспользоваться нашими Услугами.',
    personalInformationTitle: 'Использование персональной информации',
    personalInformationDescription: 'Если вы решите воспользоваться нашими Услугами, мы можем - в соответствии ' +
      'с требованиями конкретной Услуги - попросить вас согласиться на сбор ' +
      'и использование вашей персональной информации. Данная информация будет использоваться строго для оказания Услуги и совершенствования нашего сервиса. \n' +
      'Мы не будем разглашать вашу персональную информацию третьим лицам, кроме ' +
      'как в случаях когда этого требует оказываемая Услуга и при условии вашего прямого согласия на такое раскрытие.',
    cookieTitle: 'Куки',
    cookieDescription: 'Несмотря на то, что мы не используем куки - файлы с небольшим количеством данных, которые обычно используются как уникальные анонимные идентификаторы, направляемые в ваш браузер с веб-страниц, которые вы посещаете, и которые хранятся во внутренней памяти вашего устройства - возможны случаи, когда наши Услуги используют код третьих лиц и библиотеки, которые могут использовать куки для сбора информации и улучшения их сервисов.',
    cookieDescription2: 'У вас в любом случае будет возможность принять данные куки или отказаться от них. В случае отказа от куки, доступ к некоторым разделам сервиса может быть ограничен.',
  },
}