import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.svg'
import planet from '../../assets/images/planet.svg'
import styles from './index.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { LOCAL_STORAGE_LNG_KEY } from '../../i18n'

const cn = classNames.bind(styles)

const Header = () => {
  const { t, i18n } = useTranslation()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const ref = useRef(null)
  const [menuIsOpen, setIsOpenMenu] = useState(false)
  const isRuLng = i18n.language === 'ru'

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleMenuIconClick = () => {
    if (!menuIsOpen) {
      document.body.classList.add('menu-open')
    } else {
      document.body.classList.remove('menu-open')
    }
    setIsOpenMenu(!menuIsOpen)
  }

  const changeLanguage = () => {
    const lng = isRuLng ? 'en' : 'ru'
    i18n.changeLanguage(lng)
    localStorage.setItem(LOCAL_STORAGE_LNG_KEY, lng)
  }

  const currentLangBig = isRuLng ? 'English' : 'Русский'
  const currentLangSmall = isRuLng ? 'En' : 'Ру'

  return (
    <header className={cn('container', styles.HeaderContent)}>
      <div className={styles.Mobile}>
        <div className={styles.MobileMenu}>
          <div
            ref={ref}
            className={cn(styles.MenuIcon, menuIsOpen && styles.MenuIconOpen)}
            onClick={handleMenuIconClick}
          />

          {menuIsOpen && (
            <ul className={cn(styles.MenuList)}>

              <li onClick={() => handleMenuIconClick()}>
                <Link to='/'>{t('home')}</Link>
              </li>
              <li onClick={() => handleMenuIconClick()}>
                <Link to='/countries'>{t('countries')}</Link>
              </li>
              <li onClick={() => handleMenuIconClick()}>
                <Link to='/yachts'>{t('yachts')}</Link>
              </li>
              <li onClick={() => handleMenuIconClick()}>
                <Link to='/helicopters'>{t('helicopters')}</Link>
              </li>
              <li onClick={() => handleMenuIconClick()}>
                <Link to='/real-estate'>{t('realEstate')}</Link>
              </li>
            </ul>
          )}
        </div>

        <Link to='/' className={styles.Logo}>
          <img src={logo} alt="Logo"/>
        </Link>
      </div>

      <nav className={cn(styles.Nav)}>
        <Link to='/'>{t('home')}</Link>
        <Link to='/countries'>{t('countries')}</Link>
        <Link to='/yachts'>{t('yachts')}</Link>
        <Link to='/helicopters'>{t('helicopters')}</Link>
        <Link to='/real-estate'>{t('realEstate')}</Link>
      </nav>

      <button onClick={changeLanguage} className={styles.LngBtn}>
        <img src={planet} alt="Planet Icon" />
        {windowWidth > 768 ? currentLangBig : currentLangSmall }
      </button>
    </header>
  )
}

export default Header