import React, {useRef, useState} from 'react'
import styles from '../index.module.scss'
import classNames from 'classnames'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import { ReactComponent as HelicopterIcon } from '../../../assets/images/helicopter.svg'
import { ReactComponent as VillaIcon } from '../../../assets/images/villa.svg'
import { ReactComponent as BoatIcon } from '../../../assets/images/boat.svg'
import Button from '../../../components/Button'
import ModalOffer from '../../../components/ModalOffer'
import ImageGlobal from '../../../assets/images/glonbe.svg'
import indonesiaImg from '../../../assets/images/indonesia.png'
import frenchPolynesiaImg from '../../../assets/images/french-polynesia.png'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'

const cn = classNames.bind(styles)
const MainSection = () => {
  const { t, i18n } = useTranslation()
  const inputRef = useRef(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [value, onChange] = useState()
  const [offerType, onChangeOfferType] = useState<'real_estate' | 'helicopter' | 'yacht'>('real_estate')
  const [isInputActive, setIsInputActive] = useState(false)
  const [city, setCity] = useState('')

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleInputFocus = () => {
    setIsInputActive(true)
  }

  const handleInputBlur = () => {
    !city && setIsInputActive(false)
  }

  return (
    <div className={styles.MainSectionWrapper}>
      <section className={cn(styles.MainSection, 'container')}>
        <div className={cn(styles.MainSectionContainer)}>
          <div className={styles.ValuesContainer}>
            <h1 className={styles.Title}>
              <Trans t={t} i18nKey="rent" />
            </h1>

            <div className={styles.ProductTypeContainer}>
              <button
                className={cn(styles.ProductTypeBtn, offerType === 'real_estate' ? styles.ProductTypeBtnActive : '')}
                onClick={() => onChangeOfferType('real_estate')}
              >
                <VillaIcon />
                {t('villa')}
              </button>
              <button
                className={cn(styles.ProductTypeBtn, offerType === 'yacht' ? styles.ProductTypeBtnActive : '')}
                onClick={() => onChangeOfferType('yacht')}
              >
                <BoatIcon />
                {t('yacht')}
              </button>
              <button
                className={cn(styles.ProductTypeBtn, offerType === 'helicopter' ? styles.ProductTypeBtnActive : '')}
                onClick={() => onChangeOfferType('helicopter')}
              >
                <HelicopterIcon />
                {t('helicopter')}
              </button>
            </div>

            <div className={styles.DatesContainer}>
              <div className={styles.InputContainer}>
                <div className={cn(styles.cityInput, isInputActive ? styles.cityInputActive : undefined)}>
                  <label htmlFor="city" onClick={handleInputFocus}>
                    {t('city')}
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    onChange={(e) => setCity(e?.target?.value)}
                    onBlur={handleInputBlur}
                  />
                </div>

                <div className={styles.Divider}/>

                {/*<Select optionsList={options}  />*/}
                <div className={cn(styles.DateRangePickerContainer )}>
                  <span
                    onClick={() => {
                      console.log(inputRef.current)
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      return inputRef.current?.focus()
                    }}
                    className={cn(styles.DateRangePickerLabel, !value ? styles.DateRangePickerLabelEmpty : '')}>
                    {t('dates')}
                  </span>
                  <span className={styles.Dates}>
                    {value ?
                        `${moment(value?.[0]).format('DD/MM/yyyy')} - ${value[1] ? moment(value?.[1]).format('DD/MM/yyyy') : ''}`
                      : t('selectDates')
                    }
                  </span>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <DateRangePicker onChange={onChange} value={value}
                      className={cn(styles.DateRangePicker, isCalendarOpen ? styles.DateRangePickerActive : '', !value ? styles.DateRangePickerEmpty : '')}
                      calendarIcon={null}
                      clearIcon={null}
                      minDate={new Date()}
                      onCalendarOpen={() => setIsCalendarOpen(true)}
                      onCalendarClose={() => setIsCalendarOpen(false)}
                      locale={i18n.language}
                      yearPlaceholder={''}
                      monthPlaceholder={''}
                    />
                  }
                </div>

                <Button
                  disabled={!city || !value}
                  onClick={() => openModal()}
                >
                  {t('find')}
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.PictureContainer}>
            <img src={ImageGlobal} alt="Planet"/>

            <div className={styles.PictureItem}>
              <img src={indonesiaImg} alt="Indonesia"/>
              <div>
                <h2>
                  Bulgari Bali
                </h2>
                <span>
                  {t('Indonesia')}
                </span>
              </div>
            </div>

            <div className={cn(styles.PictureItem, styles.PictureItemPosition)}>
              <img src={frenchPolynesiaImg} alt="French Polynesia"/>
              <div>
                <h2>
                  42m Gulf Craft <br/>
                  Luxury Yacht
                </h2>
                <span>
                  {t('FrenchPolynesia')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalOffer isOpen={isModalOpen} onClose={closeModal}/>
    </div>
  )
}

export default MainSection