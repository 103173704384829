import React, {useEffect, useState} from 'react'
import InnerPageContainer from '../../components/InnerPageContainer'
import ImgItem from '../../components/ImgItem/ImgItem'
import styles from './index.module.scss'
import { ObjectProps, fetchCountries } from '../../api'
import { Link } from 'react-router-dom'
import ScrollToTop from '../../components/ScrollToTop'
import { useTranslation } from 'react-i18next'

const CountriesPage = () => {
  const { t, i18n} = useTranslation()
  const [countryList, setCountryList] = useState<ObjectProps[]>([])

  useEffect(() => {
    fetchCountries(setCountryList)
  }, [])

  return (
    <>
      <ScrollToTop/>
      <InnerPageContainer title={t('allCountries')}>
        {
          countryList?.map((item) => {
            return (
              <Link key={item.id} to={`/country/${item.id}`}>
                <ImgItem
                  imgUrl={item.image}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  name={item[`name_${i18n.language}`]}
                  classNameImage={styles.Image}
                />
              </Link>
            )
          })
        }
      </InnerPageContainer>
    </>
  )
}

export default CountriesPage