import React from 'react'
import { FacilityProps } from '../../../api'
import { useTranslation } from 'react-i18next'

const FacilityList = ({
  list,
  title,
}: { list: FacilityProps[], title: string }) => {
  const { i18n} = useTranslation()

  return (
    <>
      <h3>{title}</h3>

      <ul>
        {
          list?.map((item) => {
            return (
              <li key={item.id}>
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  item?.[`name_${i18n.language}`]
                }
              </li>
            )
          })
        }
      </ul>
    </>
  )
}

export default FacilityList