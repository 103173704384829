import React from 'react'
import HomePageSection from './components/HomePageSection'
import MainSection from './components/MainSection'
import ScrollToTop from '../../components/ScrollToTop'
import PopularObjects from './components/PopularObjects'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  const { t } = useTranslation()

  return (
    <main style={{ overflow: 'hidden' }}>
      <ScrollToTop/>
      <MainSection />

      <HomePageSection
        title={t('bestCountriesForVacation')}
        btnUrl='/countries'
      />

      <PopularObjects />
    </main>
  )
}

export default HomePage