import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchObjectById, ObjectProps, postReqGetOffer } from '../../api'
import FacilityList from './components'
import Questions from './components/Questions'
import styles from './index.module.scss'
import Button from '../../components/Button'
import { Formik } from 'formik'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import classNames from 'classnames'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import ModalOffer from '../../components/ModalOffer'
import ScrollToTop from '../../components/ScrollToTop'
import Carousel from './components/Carousel'
import { validateRussianPhoneNumber } from '../../helpers'
import success from '../../assets/images/success.svg'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'

const cn = classNames.bind(styles)
const ObjectPage = () => {
  const { t, i18n} = useTranslation()
  const { id } = useParams()
  const [record, setRecord] = useState<ObjectProps | undefined>(undefined)
  const [value, onChange] = useState([new Date(), new Date()])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  })
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [step, setStep] = useState<'form' | 'success'>('form')
  const isFormStep = step === 'form'

  useEffect(() => {
    id && fetchObjectById(id, setRecord)
  }, [])

  const equipments = record?.facilities?.filter((item) => item.category === 'Оборудование')
  const cabins = record?.facilities?.filter((item) => item.category === 'Салон и Каюты')
  const entertainments = record?.facilities?.filter((item) => item.category === 'Развлечения')

  const center = useMemo(() => ({ lat: record?.lat || 0, lng: record?.lon || 0}), [record])

  return (
    <main className={cn('container', styles.ObjWrapper)}>
      <ScrollToTop/>

      <section >
        <span className={styles.Country}>
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            record?.country?.[`name_${i18n.language}`]
          }
        </span>

        <h1 className={styles.Title}>
          {record?.name}
        </h1>

        { record && <Carousel record={record}/> }

        <div className={styles.Main}>
          <div className={styles.LeftContainer}>
            <p className={styles.Description}>
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                record?.[`description_${i18n.language}`]
              }
            </p>

            {
              !!record?.facilities?.length && (
                <h2 className={styles.Subtitle}>
                  {t('facilities')}
                </h2>
              )
            }

            {
              !!record?.facilities?.length && (
                <div className={styles.FacilitiesContainer}>
                  <div>
                    {
                      !!equipments?.length && (
                        <FacilityList
                          title={t('equipment')}
                          list={equipments || []}
                        />
                      )
                    }

                    {
                      !!entertainments?.length && (
                        <FacilityList
                          title={t('entertainment')}
                          list={entertainments|| []}
                        />
                      )
                    }
                  </div>

                  <div>
                    {
                      !!cabins?.length && (
                        <FacilityList
                          title={t('salonAndCabins')}
                          list={cabins || []}
                        />
                      )
                    }
                  </div>
                </div>
              )
            }

            {
              !equipments?.length &&
              !equipments?.length &&
              !cabins?.length && (
                <div className={cn(styles.FacilitiesContainer, styles.FacilitiesContainerWithoutTitle)}>
                  <FacilityList
                    title=''
                    list={record?.facilities || []}
                  />
                </div>
              )
            }

            <h2 className={styles.Subtitle}>
              {t('location')}
            </h2>

            <div className={styles.MapContainer}>
              {isLoaded && record && (
                <GoogleMap
                  mapContainerClassName="map-container"
                  center={center}
                  zoom={10}
                >
                  <Marker position={{ lat: record?.lat || 0, lng: record?.lon || 0 }} />
                </GoogleMap>
              )}
            </div>

            <h2 className={styles.Subtitle}>
              {t('faq')}
            </h2>

            <Questions />
          </div>

          <div className={styles.FormContainer}>
            {
              isFormStep && (
                <>
                  <h3 className={styles.RequestTitle}>
                    {t('submitYourApplication')}
                  </h3>

                  <p className={styles.RequestDescription}>
                    {t('willContact')}
                  </p>
                </>
              )
            }

            {
              isFormStep ? (
                <Formik
                  initialValues={{
                    phone: '',
                    comment: '',
                  }}
                  validate={values => {
                    const errors = {}
                    if (!validateRussianPhoneNumber(values.phone)) {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      errors.phone = t('wrongFormat')
                    }
                    return errors
                  }}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(false)
                    postReqGetOffer({
                      phone: values.phone,
                      message: values.comment,
                    })
                    setStep('success')
                    resetForm()
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit} className={styles.Form}>
                      <div className={styles.InputWrapper}>
                        <input
                          type="tel"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          placeholder={t('phoneNumber')}
                          className={cn(styles.FormInput, touched.phone && errors.phone ?  styles.FormErrorInput : '')}
                        />
                        {
                          touched.phone && errors.phone && (
                            <span className={styles.FormError}>
                              {errors.phone}
                            </span>
                          )
                        }
                      </div>

                      <span className={styles.FormDate}>
                        {value ?
                          `${moment(value?.[0]).format('DD/MM/yyyy')} - ${value[1] ? moment(value?.[1]).format('DD/MM/yyyy') : ''}`
                          : t('selectDates')
                        }
                      </span>
                      {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <DateRangePicker onChange={onChange} value={value}
                          className={cn(styles.DateRangePicker, isCalendarOpen ? styles.DateRangePickerActive : '')}
                          calendarIcon={null}
                          clearIcon={null}
                          minDate={new Date()}
                          onCalendarOpen={() => setIsCalendarOpen(true)}
                          onCalendarClose={() => setIsCalendarOpen(false)}
                          locale='ru'
                        />
                      }

                      <div className={styles.InputWrapper}>
                        <input
                          className={styles.FormInput}
                          type="text"
                          name="comment"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.comment}
                          placeholder={t("comment")}
                        />
                        {errors.comment && (
                          <span>{ errors.comment }</span>
                        )}
                      </div>

                      <Button
                        type="submit"
                        disabled={isSubmitting || !values.phone || !values.comment}
                      >
                        {t('getAnOffer')}
                      </Button>
                    </form>
                  )}
                </Formik>
              ) : (
                <div className={styles.FormSuccess}>
                  <div>
                    <img src={success} alt="Success"/>
                  </div>
                  <h2>
                    {t('ready')}
                  </h2>

                  <p>
                    {t('willContact')}
                  </p>
                </div>
              )
            }
          </div>
        </div>
      </section>

      <div className={styles.OfferBtnContainer}>
        <Button onClick={() => setIsModalOpen(true)}>
          {t('getAnOffer')}
        </Button>
      </div>

      <ModalOffer isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </main>
  )
}

export default ObjectPage