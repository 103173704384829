import React, {useEffect, useMemo, useState} from 'react'
import InnerPageContainer from '../../components/InnerPageContainer'
import ImgItem from '../../components/ImgItem/ImgItem'
import styles from './index.module.scss'
import { ObjectProps, fetchObjectsByCountryId } from '../../api'
import { Link, useParams } from 'react-router-dom'
import ScrollToTop from '../../components/ScrollToTop'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const cn = classNames.bind(styles)
const CountryPage = () => {
  const { t, i18n } = useTranslation()
  const { id } = useParams()
  const [objects, setObjects] = useState<ObjectProps[]>([])

  useEffect(() => {
    id && fetchObjectsByCountryId(id, setObjects)
  }, [])

  const country = objects?.[0]?.country

  const realEstate = useMemo(() => objects?.filter((item) => item.type === 'real_estate'), [objects])
  const yacht = useMemo(() => objects?.filter((item) => item.type === 'yacht'), [objects])
  const helicopter = useMemo(() => objects?.filter((item) => item.type === 'helicopter'), [objects])

  return (
    <main>
      <ScrollToTop/>
      <div className={'container'}>
        <div className={cn(styles.CountryImgContainer)}>
          <img src={country?.image} alt={country?.name}/>
          <h1>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              country?.[`name_${i18n.language}`]
            }
          </h1>
        </div>
      </div>

      <InnerPageContainer title={t('realEstate')} className={styles.InnerPageContainer}>
        {
          realEstate?.map((item) => {
            return (
              <Link key={item.id} to={`/offers/${item.id}`} >
                <ImgItem
                  imgUrl={item.images?.[0]?.image}
                  description={item.name}
                />
              </Link>
            )
          })
        }
      </InnerPageContainer>

      <InnerPageContainer title={t('yachts')} className={styles.InnerPageContainer}>
        {
          yacht?.map((item) => {
            return (
              <Link key={item.id} to={`/offers/${item.id}`} >
                <ImgItem
                  imgUrl={item.images?.[0]?.image}
                  description={item?.name}
                />
              </Link>
            )
          })
        }
      </InnerPageContainer>

      <InnerPageContainer title={t('helicopters')} className={styles.InnerPageContainer}>
        {
          helicopter?.map((item) => {
            return (
              <Link key={item.id} to={`/offers/${item.id}`} >
                <ImgItem
                  imgUrl={item?.images?.[0]?.image}
                  description={item?.name}
                />
              </Link>
            )
          })
        }
      </InnerPageContainer>
    </main>
  )
}

export default CountryPage