import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styles from './index.module.scss'
import classNames from 'classnames'
import close from '../../assets/images/close.svg'

const cn = classNames.bind(styles)
interface ModalProps {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const dialogRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    const modalBox = event.target as Node
    if (!dialogRef?.current?.contains(modalBox) && isOpen) {
      onClose(false)
    }
  }

  const handleKeydown = (ev: KeyboardEvent) => {
    if (ev.key === 'Escape') {
      onClose(false)
    }
  }

  useEffect(() => {
    if (!isOpen) return

    const dialogElement = dialogRef.current
    if (dialogElement) {
      dialogElement.focus()
    }

    // if (isOpen) {
    //   document.addEventListener('keydown', handleKeydown)
    // } else {
    //   document.removeEventListener('keydown', handleKeydown)
    // }

    return () => {
      if (dialogElement) {
        dialogElement.blur()
      }

      // document.removeEventListener('keydown', handleKeydown)
    }
  }, [isOpen])

  const displayClass = isOpen ? styles.show : styles.hide


  return createPortal(
    <div
      id="modal-comp"
      className={cn(displayClass, styles.modalComp)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onKeyDown={handleKeydown}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClick={handleClickOutside}
    >
      <div
        className={styles.dialog}
        ref={dialogRef}
        tabIndex={1}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onKeyDown={handleKeydown}
      >
        <span className={styles.close} onClick={() => onClose(true)}>
          <img src={close} alt='Close Icon'/>
        </span>
        {children}
      </div>
    </div>,
    document.body,
  )
}

export default Modal