import React, {useEffect, useState} from 'react'
import { HomePageSectionProps } from './HomePageSection.d'
import Button from '../../../components/Button'
import {Link, useNavigate} from 'react-router-dom'
import styles from '../index.module.scss'
import ImgItem from '../../../components/ImgItem/ImgItem'
import classNames from 'classnames'
import { ObjectProps, fetchCountries } from '../../../api'
import { useTranslation } from 'react-i18next'

const cn = classNames.bind(styles)

const HomePageSection = ({ title, btnUrl }: HomePageSectionProps) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [objectList, setObjectList] = useState<ObjectProps[]>([])
  const getInfo = async () => {
    await fetchCountries(setObjectList)
  }

  useEffect(() => {
    getInfo()
  }, [])

  const handleSeeAll = () => {
    navigate(btnUrl)
  }

  return (
    <section className={cn(styles.Section, 'container')}>
      <h3>{title}</h3>

      <div className={styles.Products}>
        {
          objectList?.map((item, index) => {
            if (index > 2) {
              return null
            }

            return (
              <Link key={item.id} to={`/country'/${item.id}`} >
                <ImgItem
                  imgUrl={item.image}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  description={item[`name_${i18n.language}`]}
                />
              </Link>
            )
          })
        }
      </div>

      <Button onClick={handleSeeAll}>
        {t('all')}
      </Button>
    </section>
  )
}

export default HomePageSection