import React from 'react'
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from './pages/Home/index'
import FAQPage from './pages/FAQ/index'
import Header from './components/Header'
import Footer from './components/Footer'
import CountriesPage from './pages/Countries'
import RealEstatePage from './pages/RealEstate'
import HelicoptersPage from './pages/Helicopters'
import YachtsPage from './pages/Yachts'
import ObjectPage from './pages/ObjectPage'
import Page404 from './pages/Page404'
import PolicyPage from './pages/Policy'
import CountryPage from './pages/Country'

function App() {

  return (
    <div className="App">
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<HomePage />}  />
          <Route path="/faq" element={<FAQPage />} />

          <Route path="/countries" element={<CountriesPage />} />
          <Route path="/real-estate" element={<RealEstatePage />} />
          <Route path="/helicopters" element={<HelicoptersPage />} />
          <Route path="/yachts" element={<YachtsPage />} />
          <Route path="/policy" element={<PolicyPage />} />

          <Route path="/offers/:id" element={<ObjectPage />} />
          <Route path="/country/:id" element={<CountryPage />} />

          <Route path="*" element={<Page404 />} />
        </Routes>

        <Footer />
      </Router>

      <div id="modal"></div>
    </div>
  )
}

export default App
