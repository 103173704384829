export default {
  all: 'All',
  home: 'Home',
  countries: 'Countries',
  yachts: 'Yachts',
  helicopters: 'Helicopters',
  realEstate: 'Real Estate',

  popularObjects: 'Popular Objects',
  bestCountriesForVacation: 'Best countries for vacation',

  policy: 'Privacy Policy',

  404: {
    title: 'Page\nnot found',
    error: 'Error 404',
    description: 'Most probably this page does not exist, \n' +
      'or you have made a mistake when entering the address in your browser.',
  },

  backToHome: 'Back to main page',

  modal: {
    title: 'Please\nspecify\nyour\ncontact\ndetails',
    description: 'Within 10 minutes our manager will get in touch with you \n' +
      'and provide all necessary details.',
  },

  allCountries: 'All countries',

  Indonesia: 'Indonesia',
  FrenchPolynesia: 'French Polynesia',
  city: 'City',

  facilities: 'Facilities',
  location: 'Location',
  equipment: 'Equipment',
  entertainment: 'Entertainment',
  salonAndCabins: 'Salon and cabins',
  submitYourApplication: 'Create a booking request',
  wrongFormat: 'Wrong format',
  phoneNumber: 'Phone number',
  comment: 'Comment',
  getAnOffer: 'Get offer',
  ready: 'Done',
  willContact: `Within 10 minutes our manager will get in touch with you \nand provide all necessary details.`,
  faq: 'Frequently asked questions',
  rent: "Rent<br/> <strong>the best</strong> properties<br/>around the world",
  villa: 'Villa',
  yacht: 'Yacht',
  helicopter: 'Helicopter',
  dates: 'Dates',
  selectDates: 'Select dates',
  find: 'Find',
  whatIsYourName: 'What is your name?',
  requiredField: 'Required field',
  policyPage: {
    title: 'Confidentiality policy',
    date: 'This policy is valid as of 1-st of July 2023.',
    description: 'This policy has been created to inform users of our page of the way we collect, use and disclose Personal Date in case users decide to take advantage of our services.',
    personalInformationTitle: 'Use of personal information',
    personalInformationDescription: 'If you will decide to use our services, we may - as per the requirement of the service being used - ask you to agree to collection and use of your personal information. Such information will be used strictly for providing you with the service requested and for improvement of our service. We will not disclose collected information to third parties except cases where the service requires in and when you will provide your direct agreement to such disclosure.',
    cookieTitle: 'Cookies',
    cookieDescription: 'Despite the fact that we do not use cookies - small data files that are usually used as unique anonymised identifiers - sent to your browser from web pages that you visit and kept in the memory of your device - there may be cases when our services use code of third parties that may use cookies to assemble information and improve such third party service.',
    cookieDescription2: 'In any case you will have the ability to accept or reject such cookies. If you choose to reject a third party cookie, access to some elements of our service may be limited.',
  },
}