import React, {useEffect, useState} from 'react'
import InnerPageContainer from '../../components/InnerPageContainer'
import ImgItem from '../../components/ImgItem/ImgItem'
import { ObjectProps, fetchHelicopter } from '../../api'
import ScrollToTop from '../../components/ScrollToTop'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const HelicoptersPage = () => {
  const { t, i18n} = useTranslation()
  const [helicopterList, setHelicopterList] = useState<ObjectProps[]>([])

  useEffect(() => {
    fetchHelicopter(setHelicopterList)
  }, [])

  return (
    <>
      <ScrollToTop/>
      <InnerPageContainer title={t('helicopters')}>
        {
          helicopterList?.map((item) => {
            return (
              <Link key={item.id} to={`/offers/${item.id}`} >
                <ImgItem
                  key={item.id}
                  imgUrl={item.images?.[0]?.image}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  name={item?.country?.[`name_${i18n.language}`]}
                  description={item.name}
                />
              </Link>
            )
          })
        }
      </InnerPageContainer>
    </>
  )
}

export default HelicoptersPage