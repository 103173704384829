import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import ScrollToTop from '../../components/ScrollToTop'
import { useTranslation } from 'react-i18next'

const cn = classNames.bind(styles)

const Policy = () => {
  const { t } = useTranslation()
  return (
    <main>
      <ScrollToTop />
      <section className={cn('container', styles.Container)}>
        <h1>
          {t('policyPage.title')}
        </h1>
        <span>
          {t('policyPage.date')}
        </span>

        <p>
          {t('policyPage.description')}
        </p>

        <h2>
          {t('policyPage.personalInformationTitle')}
        </h2>

        <p>
          {t('policyPage.personalInformationDescription')}
        </p>

        <h2>
          {t('policyPage.cookieTitle')}
        </h2>

        <p>
          {t('policyPage.cookieDescription')}
          <br/>
          {t('policyPage.cookieDescription2')}
        </p>
      </section>
    </main>
  )
}

export default Policy