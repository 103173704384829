import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'

const cn = classNames.bind(styles)

export type ButtonProps = {
  /** @default button*/
  tag?: 'button' | 'outlined';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ children, tag, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className={cn(styles.Button, tag === 'outlined' && styles.ButtonOutline, props.className || '')}
    >
      {children}
    </button>
  )
}

export default Button