import React, { useState, useEffect } from 'react'
import styles from '../index.module.scss'
import classNames from 'classnames'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { ReactComponent as ArrowIcon } from '../../../assets/images/arrow.svg'
import { ObjectProps } from '../../../api'

const cn = classNames.bind(styles)
const Carousel = ({ record }: { record: ObjectProps}) => {
  const [pageWidth, setPageWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth)
    }

    // Add event listener to update page width on window resize
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isLaptop = pageWidth > 970
  const isPhone = pageWidth < 670
  let widthImg = isLaptop ? 1037 : 900
  let heightImg = isLaptop ? 632 : 510
  if (isPhone) {
    widthImg = 600
    heightImg = 211
  }

  const images = record?.images?.map((item) => {
    return (
      <img
        width={widthImg}
        height={heightImg}
        key={item.id}
        src={item.image}
        alt={item.name}
      />
    )
  })

  const renderPrevButton = () => {
    return (
      <div className={cn(styles.ControlBtn, styles.ControlBtnLeft)}>
        <ArrowIcon />
      </div>
    )
  }

  const renderNextButton = () => {
    return (
      <div className={cn(styles.ControlBtn, styles.ControlBtnRight)}>
        <ArrowIcon />
      </div>
    )
  }

  // const padding = (pageWidth - (isLaptop ? 748 : 567)) / 2

  return (
    <div className={styles.CarouselWrapper}>
      <AliceCarousel
        mouseTracking
        items={images}
        paddingLeft={1}
        paddingRight={1}
        infinite={true}
        activeIndex={1}
        disableDotsControls
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
        responsive={{ 0: { items: 1 } }}
      />
    </div>
  )
}

export default Carousel