import React from 'react'
import styles from './index.module.scss'
import logo from '../../assets/images/logo.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className={'container'}>
      <div className={styles.FooterContent}>
        <div className={styles.LogoContainer}>
          <Link to='/' className={styles.Logo}>
            <img src={logo} alt="Logo"/>
          </Link>
          <Link to='/policy' className={styles.PrivacyLink}>
            {t('policy')}
          </Link>
        </div>

        <p className={styles.Address}>
          PAYCASH FZC LLC Unit No: 2510,
          Preatoni Tower, Plot No: JLT-PH1-L2A,
          Jumeirah Lakes Towers, Dubai, UAE
        </p>
      </div>
    </footer>
  )
}

export default Footer