import React, {useState} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import arrow from '../../../assets/images/arrow.svg'
import { QuestionItemProps } from './QuestionItem.d'
import { useTranslation } from 'react-i18next'

const cn = classNames.bind(styles)
const QuestionItem = (question: QuestionItemProps) => {
  const { i18n} = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={styles.QuestionItemContainer}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={cn(styles.QuestionItemTitle, isOpen ? styles.QuestionItemTitleOpen : undefined)}>
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          question[`question_${i18n.language}`]
        }
        <img src={arrow} alt="Arrow"/>
      </div>
      <div className={cn(styles.QuestionItemContent, isOpen ? styles.QuestionItemContentOpen : undefined )}>
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          question[`answer_${i18n.language}`]
        }
      </div>
    </div>
  )
}

export default QuestionItem